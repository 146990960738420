<template>
  <div class="mb-8">
    <ul class="text-gray-600 dark:text-gray-200">
      <!-- First Education Item -->
      <li class="mb-4">
        <div class="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <span class="w-full sm:w-28 font-semibold">2026. 08</span>
          <span>Applied MSc in Data Science & AI</span>
        </div>
        <ul class="space-y-1 pl-0 sm:pl-32">
          <li>Graduated from Data ScienceTech Institute - School of Engineering</li>
        </ul>
      </li>

      <!-- Second Education Item -->
      <li class="mb-4">
        <div class="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <span class="w-full sm:w-28 font-semibold">2024. 08</span>
          <span>Bachelor's in Economics (CMI Engineer track) - Data Science for Social Sciences</span>
        </div>
        <ul class="space-y-1 pl-0 sm:pl-32">
          <li>Graduated from Paris X University</li>
        </ul>
      </li>

      <!-- Third Education Item -->
      <li class="mb-4">
        <div class="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <span class="w-full sm:w-28 font-semibold">2021. 08</span>
          <span>General Baccalaureate in Mathematics and Economics</span>
        </div>
        <ul class="space-y-1 pl-0 sm:pl-32">
          <li>Graduated from the French School of Tamatave</li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped>
/* Add any additional custom styles here */
</style>